import Vue from 'vue'

Vue.mixin({
  methods: {
    getcc1(serviceIndex, afeIndex) {
      console.log("getcc1-outer", this.editAfe);

      if(this.editAfe == 0 && this.merged[serviceIndex][0].afes[afeIndex].afenum.items){
        console.log("getcc1");
        var cc1Temp = this.lodash.groupBy(this.merged[serviceIndex][0].afes[afeIndex].afenum.items, 'ccone_code');
        console.log(cc1Temp);
        this.cc1Data = Object.keys(cc1Temp).map(key => ({ ccone_code: key, items: cc1Temp[key] }));
        this.merged[serviceIndex][0].afes[afeIndex].ccone_code = this.cc1Data[0];
      }
    },
    getcc2(serviceIndex, afeIndex) {
      console.log("getcc2-outer", this.editAfe);

      if(this.editAfe == 0 && this.merged[serviceIndex][0].afes[afeIndex].ccone_code.items){
        console.log("getcc2");
        var cc2Temp = this.lodash.groupBy(this.merged[serviceIndex][0].afes[afeIndex].ccone_code.items, 'cctwo_code');
        this.cc2Data = Object.keys(cc2Temp).map(key => ({ cctwo_code: key, items: cc2Temp[key] }));
        this.merged[serviceIndex][0].afes[afeIndex].cctwo_code = this.cc2Data[0];
      }
    },
    getcc3(serviceIndex, afeIndex) {
      console.log("getcc3-outer", this.editAfe);

      if(this.editAfe == 0 && this.merged[serviceIndex][0].afes[afeIndex].cctwo_code.items){
        console.log("getcc3");
        var cc3Data = this.merged[serviceIndex][0].afes[afeIndex].cctwo_code.items.filter(o => !o.ccthree_code.includes('---'));
        var cc3Temp = this.lodash.groupBy(cc3Data, 'ccthree_code');
        this.cc3Data = Object.keys(cc3Temp).map(key => ({ ccthree_code: key, items: cc3Temp[key] }));
        this.merged[serviceIndex][0].afes[afeIndex].ccthree_code = this.cc3Data[0];
      }
    },

    getcc1Create(serviceIndex) {
      if(this.createAfe == 0 && this.createAfenum.items){
        console.log(this.createAfe, "getcc1Create");
        var cc1Temp = this.lodash.groupBy(this.createAfenum.items, 'ccone_code');
        this.cc1CreateData = Object.keys(cc1Temp).map(key => ({ ccone_code: key, items: cc1Temp[key] }));
        this.createCC1Code = this.cc1CreateData[0];
      }
    },
    getcc2Create(serviceIndex) {
      if(this.createAfe == 0 && this.createCC1Code.items){
        console.log(this.createAfe, "getcc2Create");
        var cc2Temp = this.lodash.groupBy(this.createCC1Code.items, 'cctwo_code');
        this.cc2CreateData = Object.keys(cc2Temp).map(key => ({ cctwo_code: key, items: cc2Temp[key] }));
        this.createCC2Code = this.cc2CreateData[0];
      }
    },
    getcc3Create(serviceIndex) {
      if(this.createAfe == 0 && this.createCC2Code.items){
        console.log(this.createAfe, "getcc3Create");
        var cc3Data = this.createCC2Code.items.filter(o => !o.ccthree_code.includes('---'));
        var cc3Temp = this.lodash.groupBy(cc3Data, 'ccthree_code');
        this.cc3CreateData = Object.keys(cc3Temp).map(key => ({ ccthree_code: key, items: cc3Temp[key] }));
        this.createCC3Code = this.cc3CreateData[0];
      }
    },
  },
})
