const protocol = window.location.protocol;
// const unsecure_url = "http://localhost:3000";
// const unsecure_url = 'https://rtnest.org';
// const secure_url = 'https://rtnest.org';
const secure_url = "https://test2api.rtnest.ca";
const unsecure_url = "http://test2api.rtnest.ca";
export const backend_url = protocol === "http:" ? unsecure_url : secure_url;

// 'http://localhost:3000';
//  'http://138.197.159.239:8080';
//'https://test2api.rtnest.ca';
//distinctionapi.rtnest.org
