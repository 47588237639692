import Vue from 'vue'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import fonts from "./fonts";
import { logo } from './logo.js'

pdfMake.vfs = fonts.pdfMake.vfs;

Vue.mixin({
  methods: {
    printInvoice(invoice, vwref, highestAfe, serviceData, afes, currency, subtotal, tax, company_name, manager, vendorLogo, companyLogo, allHistorySorted, invoiceModificationHistoryChanges, invoiceModificationHistoryAdditions, invoiceModificationHistoryRemovals){
      serviceData = this.lodash.sortBy(serviceData, 'name')
      var invoicedate = this.$moment(invoice.creation_date).format('MM/DD/YYYY');
      console.log(allHistorySorted);
      var services = [];
      var bodyData = [];


      //Make table headers
      bodyData.push([
        {
          text: 'Service',
          fillColor: '#eeeeee',
          margin: [2,2,2,2],
          style: 'itemsHeader',
        },
        {
          text: 'Code',
          fillColor: '#eeeeee',
          margin: [0,2,2,2],
          style: 'itemsHeader',
        },
        {
          text: 'Start - End',
          fillColor: '#eeeeee',
          margin: [0,2,2,2],
          style: 'itemsHeader',
        },
        {
          text: 'Qty',
          fillColor: '#eeeeee',
          margin: [0,2,2,2],
          style: 'itemsHeader',
        },
        {
          text: 'Price',
          fillColor: '#eeeeee',
          margin: [0,2,2,2],
          style: 'itemsHeader',
        },
        {
          text: 'UOM',
          fillColor: '#eeeeee',
          margin: [0,2,2,2],
          style: 'itemsHeader',
        },
        {
          text: 'Discount',
          fillColor: '#eeeeee',
          margin: [0,2,2,2],
          style: 'itemsHeader',
        },
        {
          text: 'Total',
          fillColor: '#eeeeee',
          margin: [0,2,2,2],
          style: 'itemsHeaderLast',
        }]
      );

      serviceData = this.lodash.uniqBy(this.lodash.sortBy(serviceData, 'name'), 'vsid');
      const that = this;

      for (var i = 0; i < afes.length; i++) {
        var index = serviceData.findIndex(x=>x.vsid == afes[i].vsid)
        if(index >= 0){
          afes[i].type = serviceData[index].type;
          afes[i].pu = serviceData[index].pu;
          afes[i].discount = serviceData[index].discount;
          afes[i].quantity = serviceData[index].quantity;
          afes[i].uom = serviceData[index].uom;

          if(serviceData.filter(x=>x.vsid == afes[i].vsid).length > 0){
            serviceData.splice(index, 1);
          }
        }
      }

      var afeTemp = [];
      var serviceTemp = [];

      var uni = this.lodash.uniqBy(afes, "vsid");

      uni.forEach(afe => {
        var filter = afes.filter(x=>x.vsid == afe.vsid);
        var allAfes = [];

        for (var i = 0; i < filter.length; i++) {
          allAfes.push({
            "afenum": filter[i].afenum,
            "ccone_code": filter[i].ccone_code,
            "cctwo_code": filter[i].cctwo_code,
            "ccthree_code": filter[i].ccthree_code,
            "percentage": filter[i].percentage,
          })
        }
        afeTemp.push({
          service: afe,
          afes: allAfes
        })
      });

      var group = [];
      afeTemp.forEach(afe => {
        var filter = afeTemp.filter(x=>JSON.stringify(x.afes) == JSON.stringify(afe.afes));
        if(filter.length > 0){
          if(group.filter(x => JSON.stringify(x) == JSON.stringify(filter)).length == 0){
            group.push(filter);
          }
        }
      });
      var merged = group;

      serviceData.forEach(function(sourceRow) {
        var dataRow = [];
        //var date2 = 'test';

        dataRow.push({
          text: sourceRow.name,
          style: 'serviceLineStyle',
          margin: [0,0,0,0],
          border: [false, false, false, false],
        });
        dataRow.push({
          text: sourceRow.type,
          style: 'serviceLineStyle',
          margin: [0,0,0,0],
          border: [false, false, false, false],
        });
        var date = that.$moment(sourceRow.start_date).format('MM/DD/YYYY') + ' - ' + that.$moment(sourceRow.end_date).format('MM/DD/YYYY');
        dataRow.push({
          text: date,
          style: 'serviceLineStyle',
          margin: [0,0,0,0],
          border: [false, false, false, false],
        });
        dataRow.push({
          text: sourceRow.quantity,
          style: 'serviceLineStyle',
          margin: [0,0,0,0],
          border: [false, false, false, false],
        });
        dataRow.push({
          text: "$" + sourceRow.pu.toFixed(2),
          style: 'serviceLineStyle',
          margin: [0,0,0,0],
          border: [false, false, false, false],
        });
        dataRow.push({
          text: sourceRow.uom,
          style: 'serviceLineStyle',
          margin: [0,0,0,0],
          border: [false, false, false, false],
        });
        dataRow.push({
          text: sourceRow.discount + '%',
          style: 'serviceLineStyle',
          margin: [0,0,0,0],
          border: [false, false, false, false],
        });

        var total =
          (sourceRow.quantity * sourceRow.pu) - ((sourceRow.discount/100) * sourceRow.quantity * sourceRow.pu
        );
        total = (total/1).toFixed(2);
        total = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "");

        dataRow.push({
          text: "$" + total,
          style: 'serviceLineStyleLast',
          margin: [0,0,0,0],
          border: [false, false, false, false],
        });

        bodyData.push(dataRow)
      });

      var afeTables = [];

      merged.forEach(function(afe) {
        var dataRow = [];
        var afecolumn = [];
        var sColumn = [
          [
            {
              text: " ",
              margin: [0,0,0,0],
              fontSize: 1,
              border: [false, true, false, false],
            },
            {
              text: " ",
              margin: [0,0,0,0],
              fontSize: 1,
              border: [false, true, false, false],
            },
            {
              text: " ",
              margin: [0,0,0,0],
              fontSize: 1,
              border: [false, true, false, false],
            },
            {
              text: " ",
              margin: [0,0,0,0],
              fontSize: 1,
              border: [false, true, false, false],
            },
            {
              text: " ",
              margin: [0,0,0,0],
              fontSize: 1,
              border: [false, true, false, false],
            },
            {
              text: " ",
              margin: [0,0,0,0],
              fontSize: 1,
              border: [false, true, false, false],
            },
            {
              text: " ",
              margin: [0,0,0,0],
              fontSize: 1,
              border: [false, true, false, false],
            },
            {
              text: " ",
              margin: [0,0,0,0],
              fontSize: 1,
              border: [false, true, false, false],
            }
          ]
        ];

        afe.forEach(service => {
          var date = that.$moment(service.service.start_date).format('MM/DD/YYYY') + ' - ' + that.$moment(service.service.end_date).format('MM/DD/YYYY');
          var total = (service.service.quantity * service.service.pu) - ((service.service.discount/100) * service.service.quantity * service.service.pu);

          sColumn.push([
            {
              style: 'serviceLineStyle',
              text: service.service.name,
              margin: [0,0,0,0],
              border: [false, false, false, false],
            },
            {
              text: service.service.type,
              margin: [0,0,0,0],
              style: 'serviceLineStyle',
              border: [false, false, false, false],
            },
            {
              text: date,
              margin: [0,0,0,0],
              style: 'serviceLineStyle',
              border: [false, false, false, false],
            },
            {
              text: service.service.quantity,
              margin: [0,0,0,0],
              style: 'serviceLineStyle',
              border: [false, false, false, false],
            },
            {
              text: "$" + service.service.pu.toFixed(2),
              margin: [0,0,0,0],
              style: 'serviceLineStyle',
              border: [false, false, false, false],
            },
            {
              text: service.service.uom,
              margin: [0,0,0,0],
              style: 'serviceLineStyle',
              border: [false, false, false, false],
            },
            {
              text: service.service.discount + "%",
              margin: [0,0,0,0],
              style: 'serviceLineStyle',
              border: [false, false, false, false],
            },
            {
              text: "$" + total.toFixed(2), style: 'itemsLast',
              margin: [0,0,0,0],
              style: 'serviceLineStyleLast',
              border: [false, false, false, false],
            }
          ]);
        })

        afecolumn.push([
          {
            text: ' ',
            style: 'afeItemsHeader',
            border: [false, false, false, false],
          },
          {
            text: 'AFE # / PO',
            fillColor: '#eeeeee',
            margin: [2,2,2,2],
            style: 'afeItemsHeader',
            border: [false, false, false, false],
          },
          {
            text: '%',
            fillColor: '#eeeeee',
            margin: [2,2,2,2],
            style: 'afeItemsHeader',
            border: [false, false, false, false],
          },
          {
            text: 'Cost Code #1 / Major',
            fillColor: '#eeeeee',
            margin: [2,2,2,2],
            style: 'afeItemsHeader',
            border: [false, false, false, false],
          },
          {
            text: 'Cost Code #2 / Minor',
            fillColor: '#eeeeee',
            margin: [2,2,2,2],
            style: 'afeItemsHeader',
            border: [false, false, false, false],
          },
          {
            text: 'Cost Code #3 / Description',
            fillColor: '#eeeeee',
            margin: [2,2,2,2],
            style: 'afeItemsHeader',
            border: [false, false, false, false],
          },
          {
            text: ' ',
            style: 'afeItemsHeader',
            border: [false, false, false, false],
          }
        ]);
        afe[0].afes.forEach(function(sourceRow) {
          dataRow = [];
          dataRow.push({
            text: "",
            style: 'serviceLineStyle',
            border: [false, false, false, false],
          });

          if(sourceRow.afe){
            dataRow.push({
              text: sourceRow.afe,
              style: 'serviceLineStyle',
              border: [false, false, false, false],
            });
          }
          else{
            dataRow.push({
              text: sourceRow.afenum,
              style: 'serviceLineStyle',
              border: [false, false, false, false],
            });
          }


          dataRow.push({
            text: sourceRow.percentage + "%",
            style: 'serviceLineStyle',
            border: [false, false, false, false]
          });
          dataRow.push({
            text: sourceRow.ccone_code,
            style: 'serviceLineStyle',
            border: [false, false, false, false],
          });
          dataRow.push({
            text: sourceRow.cctwo_code,
            style: 'serviceLineStyle',
            border: [false, false, false, false]
          });
          if(sourceRow.ccthree_code.includes('---')){
            dataRow.push({
              text: "",
              style: 'serviceLineStyle',
              border: [false, false, false, false],
            });
          }
          else{
            dataRow.push({
              text: sourceRow.ccthree_code,
              style: 'serviceLineStyle',
              border: [false, false, false, false],
            });
          }

          dataRow.push({
            text: "",
            style: 'serviceLineStyle',
            border: [false, false, false, false],
          });

          afecolumn.push(dataRow)
        });


        var sTable = {
          style: 'noBorders',
          widths: [ 98, 40, 62, 45, 58, 45, 50, "*"],
          headerRows: 1,
          body: sColumn
        }
        var table = {
          style: 'noBorders',
          widths: [ 98, 95, 30, '*', '*', '*', 40],
          headerRows: 1,
          margin: [0,0,0,40],
          body: afecolumn
        }

        afeTables.push(
        // {
        //   background: 'black',
        //   color: 'white',
        //   fontSize: 13,
        //   bold: true,
        //   margin: [0,20,0,5],
        //   text: [afe.code],
        // },
        {
          margin: [0,0,0,0],
          style: 'noBorders',
          table: sTable
        },{
          margin: [0,10,0,10],
          style: 'noBorders',
          table: table
        })
      });

      var serviceListCombined = {
        table: {
          body: [
            [
              {
                text: 'Service',
                style: 'itemsHeader',
              },
              {
                text: 'Code',
                style: 'itemsHeader',
              },
              {
                text: 'Start - End Date',
                style: 'itemsHeader',
              },
              {
                text: 'Qty',
                style: 'itemsHeader',
              },
              {
                text: 'Price',
                style: 'itemsHeader',
              },
              {
                text: 'UOM',
                style: 'itemsHeader',
              },
              {
                text: 'Discount',
                style: 'itemsHeader',
              },
              {
                text: 'Total',
                style: 'itemsHeaderLast',
              }
            ],
            [
              {
                text: 'Service',
                style: 'itemsHeader',
              },
              {
                text: 'Code',
                style: 'itemsHeader',
              },
              {
                text: 'Start - End Date',
                style: 'itemsHeader',
              },
              {
                text: 'Qty',
                style: 'itemsHeader',
              },
              {
                text: 'Price',
                style: 'itemsHeader',
              },
              {
                text: 'UOM',
                style: 'itemsHeader',
              },
              {
                text: 'Discount',
                style: 'itemsHeader',
              },
              {
                text: 'Total',
                style: 'itemsHeaderLast',
              }
            ],
          ]
        }
      };

      var afePage = '';
      if(afes.length > 0){
        var before = 'before';
        if(serviceData.length < 15){
          before = '';
        }
        afePage = afeTables
      }

      var invoicePaymentStatus = "Unpaid";
      if(invoice.accountant_paid === 1){
        invoicePaymentStatus = "Paid " + this.$moment(invoice.accountant_mdate).format('MM/DD/YYYY');
      }

      var uwi = "";
      var surface = "";
      var ln = "";
      var bottom = "";
      var bottomIcon = "";


      if(vwref){
        uwi = vwref.uwi;
        ln = vwref.ln;
        surface = vwref.lsdsurface;
        bottom = '';

        if(vwref.lsdbottom){
          if(!vwref.lsdbottom.includes('----')){
            var bottomTemp = "Bottom: " + vwref.lsdbottom + '\n';
            bottomIcon = {text: ' ', style: 'icon' };
            bottom = {text: bottomTemp, style: 'invoiceBillingDetails'};
          }
        }
      }
      else{
        if(highestAfe){
          uwi = highestAfe.uwi;
          surface = highestAfe.lsdsurface;
          ln = highestAfe.ln;
          if(!highestAfe.lsdbottom.includes('----')){
            var bottomTemp = "Bottom: " + highestAfe.lsdbottom + '\n';
            bottomIcon = {text: ' ', style: 'icon' };
            bottom = {text: bottomTemp, style: 'invoiceBillingDetails'};
          }
        }
      }

      var qpdiscountsubtotal = this.formatPrice((this.subtotal * invoice.discount/100));
      var qpsubtotal = this.formatPrice(this.subtotal - (this.subtotal * invoice.discount/100));
      var qpgstotal = this.formatPrice((this.subtotal - (this.subtotal * invoice.discount/100)) * (invoice.tax_percentage/100));
      var bank = '';

      if(invoice.bankaccount && manager !== 1){
        bank = 'Bank Account: ' + invoice.bankaccount;
      }

      var quickpay = [{

      }];

      var invoiceChangeHistory = [{
        text: [
          {text: ' ', style: 'icon' },
          {text: 'Invoice Changes:', style: 'invoiceBillingTitle' },
        ],
        margin: [0,30,0,10],
      }];

      // <div v-for="group in invoiceModificationHistoryChanges">
      //     <h5 class="mb-0 py-2">Resubmission Date: {{group.date | moment("MM/DD/YYYY hh:mm:ss A")}}</h5>
      //     <!-- <h5 class="mb-3">Updates <i class="fa fa-edit"></i></h5> -->
      //     <div class="mb-3" v-for="(change, i) in group.groupedUpdatedServices" v-if="group.groupedUpdatedServices.length > 0">
      //       <p class="mb-0"><strong><span class="text-capitalize">{{change.name}}</span></strong></p>
      //       <p class="mb-0" v-for="line in change.code"><span v-if="line.comment">{{line.comment}}</span></p>
      //     </div>
      //   <div class="col-xl-4" style="background: #4bdf603d; padding: 15px;">
      //     <!-- <h5 class="mb-3">Additions <i class="fa fa-plus"></i></h5> -->
      //     <p v-for="(line, i) in group.addedServices" v-if="group.addedServices.length > 0" class="mb-0"><strong><span class="text-capitalize">{{line.service_name}}</span></strong>: <span v-if="line.comment">${{line.comment}}</span></p>
      //     <!-- <h5 class="mb-3">Removals <i class="fa fa-minus-circle"></i></h5> -->
      //     <p v-for="(line, i) in group.removedServices" v-if="group.removedServices.length > 0" class="mb-0"><strong><span class="text-capitalize">{{line.service_name}}</span></strong>: <span v-if="line.comment">${{line.comment}}</span></p>
      // </div>


      for (var i = 0; i < invoiceModificationHistoryChanges.length; i++) {
        var date = invoiceModificationHistoryChanges[i].date;
        var headers = [];
        var changes = [];
        var additions = [];
        var removals = [];

        for (var b = 0; b < invoiceModificationHistoryChanges[i].addedServices.length; b++) {
          additions.push({
            text: [
              {text: invoiceModificationHistoryChanges[i].addedServices[b].service_name + ": $" + invoiceModificationHistoryChanges[i].addedServices[b].comment, fontSize: 9},
            ],
          })
        }
        for (var b = 0; b < invoiceModificationHistoryChanges[i].removedServices.length; b++) {
          removals.push({
            text: [
              {text: invoiceModificationHistoryChanges[i].removedServices[b].service_name + ": $" + invoiceModificationHistoryChanges[i].removedServices[b].comment, fontSize: 9},
            ],
          })
        }

        for (var y = 0; y < invoiceModificationHistoryChanges[i].groupedUpdatedServices.length; y++) {
          var comment = "";

          changes.push({
            text: [
              {text: invoiceModificationHistoryChanges[i].groupedUpdatedServices[y].name, fontSize: 11, bold: true },
            ],
          })

          for (var b = 0; b < invoiceModificationHistoryChanges[i].groupedUpdatedServices[y].code.length; b++) {
            changes.push({
              text: [
                {text: invoiceModificationHistoryChanges[i].groupedUpdatedServices[y].code[b].comment, fontSize: 9},
              ],
            })
          }
        }

        invoiceChangeHistory.push({
          margin: [0,10,5,0],
          text: [
            {text: "Resubmission Date: " + this.$moment(date).format("MM/DD/YYYY hh:mm A"), fontSize: 12, bold: true },
          ]
        },
    		{
    			style: 'tableExample',
          margin: [0,10,5,0],
    			table: {
    				headerRows: 1,
    				// dontBreakRows: true,
    				// keepWithHeaderRows: 1,
            widths: ['*', '*', '*'],
    				body: [
    					[{text: 'Updates', style: 'tableHeader'}, {text: 'Additions', style: 'tableHeader'}, {text: 'Removals', style: 'tableHeader'}],
              // headers,
              // changes,
    					[
                changes,
                additions,
                removals,
                // [{
                //   text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                // }],
                // [{
                //   text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
                // }],
    					]
    				]
    			}
    		});
      }

      var comments = [
        {
          text: [
            {text: ' ', style: 'icon' },
            {text: 'Invoice Notes - ' + invoice.invnum, style: 'invoiceBillingTitle'},
            {text: "\n" + invoice.vcn + " (" + invoice.vfname + " " + invoice.vlname + ")"},
          ],
        },
      ];

      if(invoice.quickpay_total > 0){
        comments.push(
          {
            text: [
              {style: 'commentText', text: '\n'},
            ],
          },
          {
            text: [
              {style: 'commentText', text: 'Quickpay Subtotal: $' + this.formatPrice(invoice.quickpay_total - (invoice.quickpay_total - (invoice.quickpay_total / ((invoice.tax_percentage / 100) + 1))))},
            ],
          },
          {
            text: [
              {style: 'commentText', text: 'Quickpay (GST): $' + this.formatPrice(invoice.quickpay_total)},
            ],
          }
        );
      }

      comments.push(
        {
          text: [
            {style: 'commentText', text: 'Subtotal: $' + this.formatPrice(invoice.total - (invoice.total - (invoice.total / ((invoice.tax_percentage / 100) + 1))))},
          ],
        },
        {
          text: [
            {style: 'commentText', text: 'Total (GST): $' + this.formatPrice(invoice.total)},
          ],
        }
      );


      if(invoice.accountant_paid === 1){
        comments.push(
          {
            style: 'commentText',
            text: [{text: "Paid On: ", bold: true}, this.$moment(invoice.accountant_mdate).format("MM/DD/YYYY hh:mm A")],
          }
        )
        if(invoice.check_round_date != ""){
          comments.push(
            {
              style: 'commentText',
              text: [{text: "Cheque Round Date: ", bold: true}, this.$moment(invoice.check_round_date).format("MM/DD/YYYY")],
            }
          )
          comments.push(
            {
              style: 'commentText',
              text: [{text: "Cheque Number: " + invoice.check_number, bold: true}],
            }
          )
        }
      }
      if(invoice.ac_note){
        comments.push(
          {
            style: 'commentText',
            text: "Accountant Note: " +  invoice.ac_note
          }
        )
      }
      if(allHistorySorted.length > 0 && invoice.inv_passed == 7 || invoice.inv_passed == 11 || invoice.inv_passed == 21 || invoice.inv_passed == 31 || invoice.inv_passed == 41 || invoice.inv_passed == 51){
        comments.push(
          {
            style: 'commentText',
            text: "Final Approval - AP Team Review"
          }
        )
      }

      for (var i = 0; i < allHistorySorted.length; i++) {
        var status = allHistorySorted[i].status;
        if(i == allHistorySorted.length - 1){
          status = "Submitted";
        }
        comments.push(
          {
            style: 'commentText',
            text:  status + " On : " + this.$moment(allHistorySorted[i].date).format('MM/DD/YYYY hh:mm:ss A') + " by " + allHistorySorted[i].user,
          }
        )
        if(allHistorySorted[i].comment){
          comments.push(
            {
              style: 'commentText',
              text: allHistorySorted[i].user + " Note: " + allHistorySorted[i].comment,
            }
          )
        }
      }



      var beforeTotal = 'before';
      if(serviceData.length < 3){
        beforeTotal = '';
      }

      if(invoice.quickpay_total < invoice.total && this.$moment(invoice.endtday) > this.$moment()) {
          var a = this.$moment(this.invoice.endtday);
          var b = this.$moment();
          var netDays = a.diff(b, 'days') + 1;

          quickpay = [
          [
              {
                  text: 'Subtotal',
                  style:'itemsFooterSubTitle'
              },
              {
                   text: '$' + this.formatPrice(this.subtotal),
                   style:'itemsFooterSubTitle'
              }
          ],
          [
              {
                  text:'Quick Pay Discount (' + invoice.discount + '%)',
                  style:'itemsFooterSubTitle'
              },
              {
                 text: '$' + qpdiscountsubtotal,
                  style:'itemsFooterSubTitle'
              }
          ],
          [
              {
                  text:'Quick Pay Subtotal',
                  style:'itemsFooterSubTitle'
              },
              {
                 text: '$' + qpsubtotal,
                  style:'itemsFooterSubTitle'
              }
          ],
          [
              {
                  text:'Quick Pay GST (' + invoice.tax_percentage + '%)',
                  style:'itemsFooterSubTitle'
              },
              {
                 text: '$' + qpgstotal,
                  style:'itemsFooterSubTitle'
              }
          ],
          [
              {
                  text:'Quickpay Total',
                  style:'itemsFooterSubTitle'
              },
              {
                  text: '$' + this.formatPrice(invoice.quickpay_total) + " " + currency,
                  style:'itemsFooterSubTitle'
              }
          ],
          [
              {
                  text:'Number Of Net Days (' + netDays + ')',
                  style:'itemsFooterSubTitle'
              },
              {
                  text: this.$moment(invoice.endtday).format('MM-DD-YYYY'),
                  style:'itemsFooterSubTitle'
              }
          ],
        ];
      }

      var logoAndDetails = [];
      if(vendorLogo){
        logoAndDetails.push({
          image: vendorLogo,
          width: 165,
          margin: [0,8,0,8],
        });
      }
      else{
        logoAndDetails.push({
          text: "",
          margin: [0,8,0,8],
        });
      }
      if(companyLogo){
        logoAndDetails.push({
          image: companyLogo,
          width: 165,
          margin: [0,8,0,8],
        });
      }
      else{
        logoAndDetails.push({
          text: "",
          margin: [0,8,0,8],
        });
      }
      logoAndDetails.push({
        text: "",
        margin: [0,8,0,8],
      });

      var docDefinition = {
        pageMargins: [ 30, 30, 30, 90 ],
        pageSize: 'LETTER',
        header: {

        },
        footer: {
          style: 'documentFooterRight',
          stack: [{
            margin: [0,20,20,5],
            image: logo,
            width: 40,
            height: 40
          },
          {
            text: 'Powered By RTNest Inc.',
            style: 'documentFooterCenter',
            margin: [10,0,37,35],
          }]
        },
        content: [
          {
            text: 'Invoice No: ' + invoice.invnum,
            style: 'invoiceTitle',
            width: '*'
          },
          // {
          //   columns: logoAndDetails,
          // },
          {
            columns: [
              {
                margin: [0,5,0,10],
                text: [
                  //{text: ' ', style: 'iconBig' },
                  {text: 'Billing From:', style: 'invoiceBillingTitle' },
                ],
              },
              {
                margin: [0,5,0,10],
                text: [
                  //{text: ' ', style: 'iconBig' },
                  {text: 'Billing To:', style: 'invoiceBillingTitle' },

                ],
              },
              {
                margin: [0,5,0,10],
                text: [
                  //{text: ' ', style: 'iconBig' },
                  {text: 'Details:', style: 'invoiceBillingTitle' },
                ],
              },
            ]
          },
          {
            columns: [
              {
                text: [
                  {text: ' ', style: 'icon' },
                  {text: invoice.vcn + '\n', style: 'invoiceBillingDetails', bold: true},
                  {text: ' ', style: 'icon' },
                  {text: invoice.vfname + ' ' + invoice.vlname + '\n' + '\n', style: 'invoiceBillingDetails'},
                  {text: ' ', style: 'icon' },
                  {text: invoice.addressline1 + ' ' + invoice.addressline2 + '\n', style: 'invoiceBillingDetails'},
                  {text: ' ', style: 'icon' },
                  {text: invoice.city + ', ' + invoice.province + '\n', style: 'invoiceBillingDetails'},
                  {text: ' ', style: 'icon' },
                  {text: invoice.postalcode + '\n\n' + bank, style: 'invoiceBillingDetails'},
                ],
              },
              {
                text: [
                  {text: ' ', style: 'icon' },
                  {text: company_name + '\n' + '\n' + '\n', style: 'invoiceBillingDetails', bold: true},
                  {text: ' ', style: 'icon' },
                  {text: invoice.company_addressline1 + ' ' + invoice.company_addressline2 + '\n', style: 'invoiceBillingDetails'},
                  {text: ' ', style: 'icon' },
                  {text: invoice.company_city + ', ' + invoice.company_province + '\n', style: 'invoiceBillingDetails'},
                  {text: ' ', style: 'icon' },
                  {text: invoice.company_postalcode + '\n\n', style: 'invoiceBillingDetails'},
                ],
              },
              {
                text: [
                  {text: ' ', style: 'icon' },
                  {text: 'Status: ' + invoicePaymentStatus + '\n', style: 'invoiceBillingDetails'},
                  {text: ' ', style: 'icon' },
                  {text: 'Date: ' + invoicedate + '\n' + '\n', style: 'invoiceBillingDetails'},
                  {text: ' ', style: 'icon' },
                  {text: 'Project: ' + invoice.projectname + '\n', style: 'invoiceBillingDetails'},
                  {text: ' ', style: 'icon' },
                  {text: ' UWI: ' + uwi + '\n', style: 'invoiceBillingDetails'},
                  {text: ' ', style: 'icon', bold: true },
                  {text: ' License: ' + ln + '\n', style: 'invoiceBillingDetails'},
                  {text: ' ', style: 'icon' },
                  {text: 'Surface: ' + surface + '\n', style: 'invoiceBillingDetails'},
                  bottomIcon,
                  bottom,
                  ...(invoice.ticketnum ? [{text: ' ', style: 'icon', bold: true }, {text: 'Field Ticket Number: ' + invoice.ticketnum + '\n' , style: 'invoiceBillingDetails'}] : []),
                  ...(invoice.vendor_invoice_number ? [{text: ' ', style: 'icon', bold: true }, {text: 'Vendor Invoice Number: ' + invoice.vendor_invoice_number + '\n' , style: 'invoiceBillingDetails'}] : []),
                  ...(invoice.service_order_number ? [{text: ' ', style: 'icon', bold: true }, {text: 'Service Order Number: ' + invoice.service_order_number + '\n' , style: 'invoiceBillingDetails'}] : []),
                  ...(invoice.program_number ? [{text: ' ', style: 'icon', bold: true }, {text: 'Program Number: ' + invoice.program_number + '\n' , style: 'invoiceBillingDetails'}] : []),
                  ...(invoice.treatment_type ? [{text: ' ', style: 'icon', bold: true }, {text: 'Treatment Type: ' + invoice.treatment_type + '\n' , style: 'invoiceBillingDetails'}] : []),
                  ...(invoice.vendor_invoice_date ? [{text: ' ', style: 'icon', bold: true }, {text: 'Vendor Invoice Date: ' + this.$moment(invoice.vendor_invoice_date).format('MM/DD/YYYY') + '\n' , style: 'invoiceBillingDetails'}] : [])
                ],
              }
            ]
          },
          '\n\n',
          {
            style: 'invoiceBillingDetails',
            layout: 'lightHorizontalLines',
            margin: [0,0,0,0],
            table: {
              headerRows: 1,
              widths: [ 95, 35, 54, 37, 47, 45, 50, "*" ],
              body: bodyData,
            }
          },
          afePage,
          {
            margin: [0,20,0,0],
            columns: [

              {
               // TOTAL
                table: {
                  margin: [0,10,0,0],
                  // headers are automatically repeated if the table spans over multiple pages
                  // you can declare how many rows should be treated as headers
                  headerRows: 0,
                  widths: [ '*', 80 ],

                  body: quickpay,
                }, // table
                layout: 'lightHorizontalLines'
              },
              {
                //pageBreak: beforeTotal,
               // TOTAL
                table: {

                  // headers are automatically repeated if the table spans over multiple pages
                  // you can declare how many rows should be treated as headers
                  headerRows: 0,
                  widths: [ '*', 80 ],

                  body: [
                    // Total
                    [
                        {
                            text: 'Subtotal',
                            style:'itemsFooterSubTitle'
                        },
                        {
                             text: '$' + this.formatPrice(subtotal),
                             style:'itemsFooterSubTitle'
                        }
                    ],
                    [
                        {
                            text:'Tax ' + invoice.tax_percentage + '%',
                            style:'itemsFooterSubTitle'
                        },
                        {
                           text: '$' + tax,
                           style:'itemsFooterSubTitle'
                        }
                    ],
                    [
                        {
                            text:'TOTAL',
                            style:'itemsFooterSubTitle'
                        },
                        {
                            text: '$' + this.formatPrice(invoice.total) + " " + currency,
                            style:'itemsFooterSubTitle'
                        }
                    ],
                  ]
                }, // table
                layout: 'lightHorizontalLines'
              },
            ]
          },
          {
            text: '',
            pageBreak: 'before',
          },
          comments,
          invoiceChangeHistory,
        ],
        styles: {
          commentText: {
            margin: [0,7,0,7],
            fontSize: 8,
          },
          serviceLineStyle: {
            border: [false, false, false, false],
            fontSize: 8,
          },
          serviceLineStyleLast: {
            border: [false, false, false, false],
            fontSize: 8,
            alignment: 'right',
          },
          itemsHeader: {
            bold: true,
            fontSize: 11,
          },
          afeItemsHeader: {
            bold: true,
            fontSize: 10,
          },
          tableHeader: {
            bold: true,
            fontSize: 11,
          },
          items: {
            fontSize: 8,
          },
          itemsHeaderLast: {
            alignment: 'right',
            bold: true,
            fontSize: 10,
          },
          itemsLast: {
            alignment: 'right',
          },
          documentFooterRight: {
            margin: [0,0,0,0],
            alignment:'right'
          },
          documentFooterCenter: {
            margin: [0,0,0,10],
            fontSize: 10,
            alignment:'right'
          },
          // Invoice Title
          invoiceTitle: {
            fontSize: 20,
            bold: true,
            alignment:'left',
            margin:[0,0,0,0]
          },
          // Invoice Details
          invoiceSubTitle: {
            fontSize: 12,
            alignment:'right'
          },
          invoiceSubValue: {
            fontSize: 12,
            alignment:'right'
          },
          // Billing Headers
          invoiceBillingTitle: {
            fontSize: 14,
            bold: true,
            alignment:'left',
            margin:[0,0,0,5],
          },
          // Billing Details
          invoiceBillingDetails: {
            alignment:'left',
            fontSize: 10
          },
          invoiceBillingAddressTitle: {
            margin: [0,7,0,3],
            bold: true
          },

          // Items Footer (Subtotal, Total, Tax, etc)
          itemsFooterSubTitle: {
            margin: [0,0,0,0],
            bold: true,
            fontSize: 10,
            alignment:'right',
          },
          itemsFooterSubValue: {
            margin: [0,5,0,5],
            bold: true,
            fontSize: 11,
            alignment:'center',
          },
          itemsFooterTotalTitle: {
            margin: [0,5,0,5],
            bold: true,
            fontSize: 11,
            alignment:'right',
          },
          itemsFooterTotalValue: {
            margin: [0,5,0,5],
            bold: true,
            fontSize: 11,
            alignment:'center',
          },
          icon: {
            font: 'FontAwesome',
            fontSize: '9'
          },
          iconBig: {
            font: 'FontAwesome',
          },
          center: {
            alignment:'center',
          },
        },
        defaultStyle: {
          columnGap: 20,
        },
      };

      var fonts = {
        FontAwesome: {
          normal: 'FontAwesome.ttf',
          bold: 'FontAwesome.ttf',
          italics: 'FontAwesome.ttf',
          bolditalics: 'FontAwesome.ttf'
        },
        Roboto: {
          normal: 'Roboto-Regular.ttf',
          bold: 'Roboto-Medium.ttf',
          italics: 'Roboto-Italic.ttf',
          bolditalics: 'Roboto-MediumItalic.ttf'
        }
      }

      pdfMake.createPdf(docDefinition, null, fonts).download("Invoice_" + invoice.invnum + ".pdf");
    },

  },
})
