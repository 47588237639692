import Vue from 'vue'
import Vuex from 'vuex'
import { createFlashStore } from 'vuex-flash';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginRoute: '',
    name: '',
    invoice: [],
    set: 0,
    selectedProject: null,
    count: 2
  },
  getters: {
    loginRoute: state => state.loginRoute,
    name: state => state.name,
    invoice: state => state.invoice,
    set: state => state.set,
    count: state => state.count,
  },
  mutations: {
    increment (state) {
      state.count++
    },
    change(state, loginRoute) {
      state.loginRoute = loginRoute
    },
    changeName(state, name) {
      state.name = name
    },
    changeInvoice(state, invoice) {
      state.invoice = invoice
    },
    changeSet(state, set) {
      state.set = set
    },
    setActiveProject(state, project) {
      state.selectedProject = project
    }
  },
  actions: {
    setLoginRoute: ({commit, state}, newValue) => {
      commit('SET_LOGINROUTE', newValue)
      return state.loginRoute
    },
    setName: ({commit, state}, newValue) => {
      commit('SET_NAME', newValue)
      return state.name
    },
    setInvoice: ({commit, state}, newValue) => {
      commit('SET_INVOICE', newValue)
      return state.invoice
    },
    setSet: ({commit, state}, newValue) => {
      commit('SET_SET', newValue)
      return state.set
    },
  },
  computed: {
    store() {
      return this.$store.state
    }
  },
  plugins: [
   createFlashStore()
  ]
})
