import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
Vue.mixin({
  methods: {
    setPreset(invoice) {
      this.$session.set('preset', invoice);
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    roundOff(value, decimals = 2) {
      return Number(value.toFixed(decimals));
    },

    _discount_price_per_unit(discount, pu) {
      let discounted_price_per_unit = pu * (1 - discount / 100); // pu- price per unit
      return discounted_price_per_unit.toFixed(2);
    },

    async createInvoiceHistory({
      role,
      role_id,
      userName,
      comments,
      action_desc,
      action_status,
      invoiceData,
    }) {
      const history = {
        role: role,
        userName: userName,
        comments: comments,
        action: action_desc,
        role_id: role_id,
        action_status: action_status,
        invoiceData: invoiceData,
      };
      try {
        return await this.$axios.post('/create/invoice_history/', history);
      } catch (err) {
        console.log(err, ':::error in mixins');
      }
    },
    async getInvoice(invoiceid) {
      try {
        return this.$axios.get(`/wts/get/invoice/invoiceid/${invoiceid}`);
      } catch (err) {
        console.log(err, ':::err');
      }
    },
    setInvoice(invoice, set, query) {
      if (query) {
        this.$session.set('query', query.query);
        this.$session.set('orderBy', query.orderBy);
        this.$session.set('page', query.page);
      } else {
        this.$session.set('query', '');
        this.$session.set('orderBy', { ascending: true, column: false });
        this.$session.set('page', 1);
      }

      this.$session.set('invoice', invoice);
      this.$session.set('set', set);
    },
    calcLineTotal(line) {
      let total = 0;
      if (line.invoice_discount === undefined) {
        line.invoice_discount = line.discount;
      }
      if (line.check_round_date === null) {
        total +=
          line.quantity * line.pu -
          (line.invoice_discount / 100) * line.quantity * line.pu;
      } else {
        if (
          line.invoice_quickpay != undefined &&
          line.invoice_quickpay > 0 &&
          this.$moment(line.check_round_date).isSameOrBefore(line.quickpay_date)
        ) {
          total +=
            (line.quantity * line.pu -
              (line.invoice_discount / 100) * line.quantity * line.pu) *
            (1 - line.invoice_quickpay / 100);
        } else {
          total +=
            line.quantity * line.pu -
            (line.invoice_discount / 100) * line.quantity * line.pu;
        }
      }
      return total;
    },
    calcLineTotalWithServiceEstimate(line) {
      let total = 0;
      if (line.invoice_discount === undefined) {
        line.invoice_discount = line.discount;
      }
      if (line.check_round_date === null) {
        total +=
          line.estimate_quantity * line.pu -
          (line.invoice_discount / 100) * line.estimate_quantity * line.pu;
      } else {
        if (
          line.invoice_quickpay != undefined &&
          line.invoice_quickpay > 0 &&
          this.$moment(line.check_round_date).isSameOrBefore(line.quickpay_date)
        ) {
          total +=
            (line.estimate_quantity * line.pu -
              (line.invoice_discount / 100) *
                line.estimate_quantity *
                line.pu) *
            (1 - line.invoice_quickpay / 100);
        } else {
          total +=
            line.estimate_quantity * line.pu -
            (line.invoice_discount / 100) * line.estimate_quantity * line.pu;
        }
      }
      return total;
    },
    calcLineTotalWithAfePercentage(line) {
      let total = 0;
      if (line.invoice_discount === undefined) {
        line.invoice_discount = line.discount;
      }
      if (line.check_round_date === null) {
        total +=
          line.quantity * line.pu -
          (line.invoice_discount / 100) * line.quantity * line.pu;
      } else {
        if (
          line.invoice_quickpay != undefined &&
          line.invoice_quickpay > 0 &&
          this.$moment(line.check_round_date).isSameOrBefore(line.quickpay_date)
        ) {
          total +=
            (line.quantity * line.pu -
              (line.invoice_discount / 100) * line.quantity * line.pu) *
            (1 - line.invoice_quickpay / 100);
        } else {
          total +=
            line.quantity * line.pu -
            (line.invoice_discount / 100) * line.quantity * line.pu;
        }
      }
      total = total * (line.percentage / 100);
      return total;
    },
    calcLineTotalWithAfePercentageNoDiscountNoGst(line) {
      let total = 0;
      if (line.invoice_discount === undefined) {
        line.invoice_discount = line.discount;
      }
      if (line.check_round_date === null) {
        total += line.quantity * line.pu;
      } else {
        if (
          line.invoice_quickpay != undefined &&
          line.invoice_quickpay > 0 &&
          this.$moment(line.check_round_date).isSameOrBefore(line.quickpay_date)
        ) {
          total += line.quantity * line.pu * (1 - line.invoice_quickpay / 100);
        } else {
          total += line.quantity * line.pu;
        }
      }
      total = total * (line.percentage / 100);
      return total;
    },
  },
});
